<template>
  <section class="cont" >
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <!-- 查询框 -->
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <el-radio-group v-model="filtrate.orderType" @change="changeState">
              <el-radio-button v-for="(state,idx) in statisticsStateList" :key="idx" :label="idx" :value="state.value">
                {{state.label}}
                <p class="tag" v-if="state.num != 0 && idx !== 0">{{state.num}}</p>
              </el-radio-button>
            </el-radio-group>
          </el-row>
          <el-row class="search-item m-left-auto m-right-0">
            <el-autocomplete
              v-model="keyword"
              class="width-434"
              value-key="label"
              :fetch-suggestions="querySearch"
              placeholder="请输入订单号/渠道订单号/房间号/姓名/手机号查询"
              @select="autoComplete"
            ></el-autocomplete>
              <!-- <el-input class="width-434" placeholder="请输入订单号/渠道订单号/房间号/姓名/手机号查询"  clearable @input="getPmsOrderList"></el-input> -->
          </el-row>
        </el-row>
        <el-row class="search-row flex-x-center">
          <el-row class="search-item m-right-10" v-if="showDate">
            <el-button plain class="date-type" @click="()=>{$refs['datePicker'].focus()}">
              {{dateType == 'checkin' ? '入住时间' : dateType == 'checkout' ? '离店时间' : '创建时间'}}
            </el-button>
            <el-date-picker
              ref="datePicker"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              class="width-240 date-ipt"
              v-model="dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="filterChange">
            </el-date-picker>
          </el-row>
          <el-row class="search-item m-right-10">
            <el-select class="width-150" placeholder="全部客户渠道"  v-model="filtrate.channel" @change="filterChange">
                <el-option  v-for="(label,value) in dictData['order-source']" 
                        :key="value"
                        :label="label"
                        :value="value">
                </el-option>
            </el-select>
          </el-row>
          <el-row class="search-item m-right-10">
            <el-select class="width-150" placeholder="全部房型" v-model="filtrate.roomTypeId" @change="filterChange">
                <el-option  v-for="(label, value) in roomTypeList"
                        :key="value"
                        :label="label.roomTypeName"
                        :value="label.id">
                </el-option>
            </el-select>
          </el-row>
          <!-- <el-row class="search-item m-right-10">
            <el-select class="width-150" placeholder="全部入住类型" v-model="filtrate.checkinType" @change="filterChange">
                <el-option v-for="(label,value) in dictData['hotel-checkin-type']" :key="value" :label="label" :value="value">
                </el-option>
            </el-select>
          </el-row> -->
          <el-row class="search-item m-right-10">
            <el-select class="width-150" placeholder="全部入住状态" v-model="filtrate.checkInState" @change="filterChange">
                <el-option v-for="(label,value) in dictData['hotel-checkIn-state']" :key="value" :label="label" :value="value"></el-option>
            </el-select>
          </el-row>
          <el-row class="search-item m-right-10">
            <el-select class="width-150" placeholder="全部结账状态" v-model="filtrate.checkoutState" @change="filterChange">
                <el-option v-for="(label,value) in dictData['pms-order-settle-status']" :key="value" :label="label" :value="value">
                </el-option>
            </el-select>
          </el-row>
          <el-button type="text" @click="resetFiltrate">重置筛选</el-button>
          <el-button type="primary" class="m-left-auto" @click="handleExcel">导出明细</el-button>
        </el-row>
      </el-row>
      
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn"> </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true" :span-method="objectSpanMethod" v-loading="loading">
          <el-table-column prop="bookOrderNo" label="订单号/渠道订单号" fit fixed min-width="40">
            <!-- <template slot-scope="scope">
              <el-button type='text' @click="()=>{orderDetailsBool = true;bookId = scope.row.bookOrderNo}"> {{scope.row.bookOrderNo}} </el-button>
            </template> -->
          </el-table-column>
          <el-table-column prop="id" label="预订单号" fit fixed min-width="40">
            <template slot-scope="scope">
              <el-button type='text' @click="()=>{orderDetailsBool = true;bookId = scope.row.bookId}"> {{scope.row.bookId}} </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="channel" label="渠道" min-width="30">
            <template slot-scope="scope">
              <!-- <div v-for="(list,idx) in scope.row.checkinRoomDTOList" :key="idx">
                 {{dictData['order-source'][list.channel]}}
              </div> -->
              {{dictData['order-source'] && dictData['order-source'][scope.row.channel]}}
            </template>
          </el-table-column>
          <el-table-column prop="realName" label="联系人" min-width="30"></el-table-column>
          <el-table-column prop="mobile" label="手机号" min-width="30"></el-table-column>
          <el-table-column prop="checkInType" label="入住类型" min-width="30">
            <template slot-scope="scope">
              {{scope.row.checkInType === "FULL_TIME" ? "全日房" : "钟点房"}}
            </template>
          </el-table-column>
          <el-table-column prop="prices" label="房型" min-width="30">
            <template slot-scope="scope">
              <!-- <div v-for="(list,idx) in scope.row.checkinRoomDTOList" :key="idx">
                {{list.roomTypeName}}
              </div> -->
              {{ scope.row.roomTypeName ? scope.row.roomTypeName : '未排房' }}
            </template>
          </el-table-column>
          <el-table-column label="房号" min-width="30">
            <template slot-scope="scope">
              {{ scope.row.roomNo ? scope.row.roomNo : '未排房' }}
            </template>
          </el-table-column>
          <el-table-column prop="roomRate" label="房费" min-width="30">
            <template slot-scope="scope">
              ￥{{ scope.row.roomRate || 0 }}
            </template>
          </el-table-column>
          <el-table-column prop="prices" label="入住时间" min-width="30">
             <template slot-scope="scope">
              <!-- <div v-for="(list,idx) in scope.row.checkinRoomDTOList" :key="idx"> -->
                {{ scope.row.checkInTime || scope.row.startTime}}
              <!-- </div> -->
            </template>
          </el-table-column>
          <el-table-column prop="prices" label="离开时间" min-width="30">
            <template slot-scope="scope">
              {{scope.row.endTime}}
            </template>
          </el-table-column>
          <el-table-column prop="checkInState" label="入住状态" min-width="30">
            <template slot-scope="scope">{{dictData['hotel-checkIn-state'] && dictData['hotel-checkIn-state'][scope.row.checkInState] || '未入住'}}</template>
          </el-table-column>
          <el-table-column prop="checkoutState" label="结账状态" min-width="30">
             <template slot-scope="scope">{{dictData['pms-order-settle-status'] && dictData['pms-order-settle-status'][scope.row.checkoutState]}}</template>
          </el-table-column>
          <el-table-column label="操作" min-width="30">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 'PENDING'">
                <el-button type="text" @click="changeBookState(scope.row.bookId,'confirmBook')">
                  <el-link type="primary">同意</el-link>
                </el-button>
                <el-button type="text" @click="changeBookState(scope.row.bookId,'declineBook')">
                  <el-link type="primary">拒单</el-link>
                </el-button>
              </span>
              <span v-else>
                <el-button type="text" @click="viewDetail(scope.row)">
                  <el-link type="primary">查看</el-link>
                </el-button>
              </span>
            </template>
            <!-- <template slot-scope="scope" v-else>
              
            </template> -->
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :current-page="page" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
    <!-- <canvas id="table" width="100%" height="800" @mousedown="down" @mousemove="moveStart" @mouseup="up"></canvas> -->
    <order-details :orderDetailsBool="orderDetailsBool" :checkinId="checkinId" :bookId="bookId" @closeOrderDetails="closeOrderDetails"></order-details>
  </section>
</template>

<script>
import { getPmsOrderList, getStatisticsState, bookInfoExcel, confirmBook, declineBook } from '@/api/pms/orderManage/orderManage'
import { dateFactory, getDict, exportFile } from '@/common/js/common'
import { pms } from '@/api/interface/pms';
import { mapState } from "vuex";
import OrderDetails from "../../../components/local/atrialState/OrderDetails";
export default {
  components:{
    OrderDetails
  },
  data(){
    return {
      statisticsStateList:{
        all:{
          label:"全部",
          value:""
        },
        checkinCount:{
          label:"今日预抵",
          value:"NOT_CHECKIN",
          num:0
        },
        checkoutCount:{
          label:"今日预离",
          value:"CHECKIN",
          num:0
        },
        liveCount:{
          label:"在住数量",
          value:"CHECKIN",
          num:0
        },
        notRoomCount:{
          label:"未排房",
          value:"",
          num:0
        },
        unDispose:{
          label:"待处理",
          value:"",
          num:0,
        }
      },
      roomTypeList:[],
      orderDetailsBool:false,
      bookId:"",
      checkinId: '',
      pickerOptions: {
          shortcuts: [{
            text: '入住时间',
            onClick:(ref)=>{this.changeDateType(ref,'checkin')}
          }, {
              text: '离店时间',
              onClick:(ref)=>{this.changeDateType(ref,'checkout')}
          },{
            text:"创建时间",
            onClick:(ref)=>{this.changeDateType(ref,'create')}
          }
        ]
      },
      radio: '',
      dateType:"checkin",
      dateRange:[], // 时间
      keyword:"", // 搜索
      filtrate:{
        channel:"", // 来源
        roomTypeId:"", // 房型
        checkInType:"", // 入住类型
        checkInState:"", // 入住状态
        checkoutState:"", // 结账状态
        orderType:"all", // 订单状态
        boolSettleRoom:"",
        bookOrderNo:"", // 订单号
        channelOrderNo:"",
        roomNo:"",
        realName:"",
        mobile:"",
        remark:""
      },
      tableData:[],
      total: 0, // 列表总条目数
      page: 1, // 当前页 默认第一页
      limit:10, // 每页显示数
      showDate:true,
      loading:false
    }
  },
  mounted(){
    this.getRoomTypeList()
    this.getStatisticsState()
    if(this.$route.query.state){
      this.filtrate.orderType = this.$route.query.state
      this.changeState(this.$route.query.state)
    }else{
      this.filtrate.orderType ="all"
      this.getPmsOrderList()
    }
    getDict(['order-source','hotel-checkIn-state', 'hotel-checkin-type', 'client-source', 'pms-order-settle-status'])
    
  },
  methods:{
    changeBookState(bookNo,state){ // 修改待处理预订单状态
      let params = { id:bookNo }
      this.$confirm(`是否确认${state === 'confirmBook' ? '确认' : '拒绝'}该订单？`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let requestFn = state === 'confirmBook' ? confirmBook : declineBook
        requestFn(params).then( res => {
          if(res.success){
            this.$message.success("操作成功");
            this.getPmsOrderList()
            this.getStatisticsState()
          }else{
            this.$message.warning("操作失败")
          }
        })
      })
    },
    autoComplete(e){ // 清除之前的赋值，重新选择key赋值
      let key = ['bookOrderNo','channelOrderNo','roomNo','realName','mobile','remark']
      key.forEach( item => { this.filtrate[item] = '' })
      if(e){
        !e.data && (this.keyword = "")
        this.filtrate[e.key] = e.data
        this.keyword = e.data
      }
      this.getPmsOrderList()
    },

    querySearch(queryString, cb){
      cb([
        { "label": "搜索订单号", "key": "bookOrderNo","data":queryString},
        { "label": "搜索渠道订单号", "key": "channelOrderNo","data":queryString},
        { "label": "搜索房间号", "key": "roomNo","data":queryString },
        { "label": "搜索姓名", "key": "realName","data":queryString },
        { "label": "搜索手机号", "key": "mobile","data":queryString },
        { "label": "搜索备注", "key": "remark","data":queryString }
      ])
    },

    handleExcel(){ // 报表导出
      let params = {
        page:this.page,
        limit:this.total,
        hotelId:this.hotelInfo.id,
        companyId:this.hotelInfo.storeId,
        ...this.filtrate
      }
      // let str = `${params.beginDateStr || ""}-${params.endDateStr || ""}订单数据`
      let str = '订单数据'
      bookInfoExcel(params).then( res => {
        exportFile(res,str)
      })
    },

    changeState(state){ // 改变订单的查询状态 
      this.page = 1;
      this.filtrate.checkInState = this.statisticsStateList[state].value
      this.filtrate.unDispose = false
      if(state === 'checkinCount' || state === 'checkoutCount'){ // 今日预抵与今日预离
        this.dateRange[0] = dateFactory.getDistanceToday(0,false)
        this.dateRange[1] = dateFactory.getDistanceToday(0,false)
        this.dateType = state === 'checkinCount' ? 'checkin' : 'checkout'
        this.showDate = false;
      }else{
        state === 'liveCount' ? '' : this.showDate = true;
        this.dateRange[0] = '';
        this.dateRange[1] = '';
      }
      this.filtrate.unDispose = state === 'unDispose' ? true : ''
      this.filtrate.boolSettleRoom = state === 'notRoomCount' ? true : ''
      if(!this.keyword){
        this.autoComplete()
      }else{
        this.getPmsOrderList()
      }
    },

    getRoomTypeList(){ // 获取房型列表
      let url = pms.getRoomTypeList,
          data = {
            hotelId:this.hotelInfo.id
          };
      
      this.$axios.post(url, data, 'json').then( res => {
        res.records.map( item => {
          item = {
            id:item.id,
            roomTypeName:item.roomTypeName
          }
        })
        
        this.roomTypeList = res.records
        this.roomTypeList.unshift({roomTypeName:'全部房型',id:""})
      })
    },

    getStatisticsState(){ // 获取各个状态的订单数量
    // debugger
      let data = {
        hotelId:this.hotelInfo.id,
        startTime:dateFactory.getDistanceToday(-1,false) + " 00:00:00",
        endTime:dateFactory.getDistanceToday(0,false) + " 00:00:00"
      }
      getStatisticsState(data).then( res => {
          for(let key in this.statisticsStateList){
            if(res.records){
              this.statisticsStateList[key].num = res.records[key] > 99 ? '99+' : res.records[key] || 0
            }else{
              this.statisticsStateList[key].num = 0
            }
          }
      })
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) { // 表格行合并
      if(columnIndex === 0){
        if (row.rowNum) {
          return [row.rowNum,1]
        }else{
          return [row.rowNum,1]
        }
      }
    },
    closeOrderDetails(){ // 关闭订单详情的弹窗，这里有可能对订单信息做出修改，所以重新查询列表
      this.orderDetailsBool = false
      this.getRoomTypeList()
      this.getStatisticsState()
      this.getPmsOrderList()
    },
    changeDateType(ref,dateType){ // 改变订单的时间筛选类型
      this.dateType = dateType
    },
    filterChange(){ // 筛选条件改变时，重置页码与数量
      this.page = 1;
      this.limit = 10;
      this.getPmsOrderList()
    },
    getPmsOrderList(){ // 查询订单列表
      let data = {
        page:this.page,
        limit:this.limit,
        hotelId:this.hotelInfo.id,
        companyId:this.hotelInfo.storeId,
        ...this.filtrate
      }
      data.orderType === "all" ? data.orderType = "" : ""
      if(this.dateRange.length){
        let startTime, endTime;
        if(this.dateType === 'checkin'){ // 入住时间
          startTime = 'startCheckInTime'
          endTime = 'endCheckInTime'
        }
        if(this.dateType === 'checkout'){ // 今日预离
          startTime = 'startCheckOutTime'
          endTime = 'endCheckOutTime'
        }
        if(this.dateType === 'create'){ // 订单创建时间
          startTime = 'startCreateTime'
          endTime = 'endCreateTime'
        }
        data[startTime] = this.dateRange[0]
        data[endTime] = this.dateRange[1]
      }
      this.loading = true
      getPmsOrderList(data).then( res => {
        this.total = res.total
        let mapList = [];
        res.records.forEach( item => {
          item.checkinRoomDTOList.forEach((items,index) => {
            // 这里的数据处理是为了合并相同订单号的行
            mapList.push({
              status:item.status,
              bookId:item.id,
              rowNum: index === 0 ? item.checkinRoomDTOList.length : false,
              bookOrderNo:item.bookOrderNo,
              ...items
            })
          })
        })
        this.tableData = mapList
        this.loading = false
      }).catch( ()=>{
        this.loading = false
      })
    },
    resetFiltrate(){
      for(let key in this.filtrate){
        this.filtrate[key] = ""
      }
      this.filtrate.orderType = "all"
      this.dateRange = []
      this.dateType = 'checkin'
      this.keyword = ""
      this.getPmsOrderList()
    },
    pageChange(limit){
      this.limit = limit
      this.getPmsOrderList()
    },
    handlePaging(page){
      this.page = page
      this.getPmsOrderList()
    },
    viewDetail(data) {
     this.orderDetailsBool = true;
     this.bookId = data.bookId;
     this.checkinId = data.id
    }
  },
  computed: { 
    ...mapState(['hotelInfo', 'dictData']),
  },
  watch:{
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.getRoomTypeList()
        this.getStatisticsState()
        this.getPmsOrderList()
      }
    },
    orderDetailsBool(newVal, oldVal){
      if(!newVal){
        this.bookId = ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .m-left-auto{
    margin-left:auto !important;
  }
  .m-right-0{
    margin-right:0 !important;
  }
  .flex-x-center{
    align-items:center;
  }
  /deep/.el-table .el-table__header th{
    font-size:14px !important;
  }
  .date-type{
    border-right:0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .date-ipt{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tag{
    display:inline-block;
    padding:0 2px;
    min-width: 14px;
    height:14px;
    font-size:12px;
    color:#FFF;
    background:#FF0000;
    line-height: 14px;
    text-align:center;
  }
  .room{
    width:100%;
    .room-box{
      display:flex;
      align-items:flex-start;
      
      .room-name{
        width:20%;
      }
      .room-num{
        display:flex;
        flex-wrap:wrap;
        flex:1;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .room-item{
          width:12%;
          height:30px;
          border-radius:4px;
          border:1px solid #F8F8F8;
        }
        .num-ac{
          background:#FF6301 !important;
        }
      }
      
    }
  }
</style>